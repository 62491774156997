html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
}

button:disabled {
  border-color: #bbb;
  cursor: not-allowed;
}

select {
  margin-bottom: 0.75rem;
  margin-left: 1rem;
  font-size: 1.5rem;
  padding: 0 1rem;
  height: 2.5rem;
}

h3 {
  letter-spacing: 0.1rem;
}

input {
  margin-bottom: 0.75rem;
  margin-left: 1rem;
  font-size: 1.5rem;
  padding: 0 1rem;
  height: 2.5rem;
}

input[type='date'],
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'] {
  height: 30px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  width: auto;
}

input[type='date'] :focus {
  border: 1px solid #33c3f0;
}

button {
  color: white;
  background-color: steelblue;
  transition: all 0.3s;
}

.modal button {
  transition: none;
}

label {
  display: inline;
}

p {
  margin-bottom: 0;
}

.error-container {
  margin: -1rem 1rem 1rem 1rem;
  display: flex;
  color: red;
  font-size: 1.25rem;
}

:focus {
  outline: 1px solid #000;
  border: none;
}
input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='text']:focus,
input[type='tel']:focus,
input[type='url']:focus,
input[type='password']:focus,
textarea:focus,
select:focus {
  border: 1px solid #000;
  outline: none;
}

button:focus {
  color: #fff;
  border: 1px solid #000;
  outline: none;
}

button:disabled {
  opacity: 0.5;
}

button:hover {
  color: steelblue;
  background-color: white;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
